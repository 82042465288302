import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Page from "../../components/Page";
import SEO from "../../components/SEO";

import { homeowners } from "../../helpers/navigation";

const DetailsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      img: file(
        relativePath: { eq: "homeowners/Img_HouseHolders_HowItWorks.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <Page
      page="Details"
      pageName="Деталі"
      title="Як це працює?"
      parameters={homeowners}
    >
      <SEO title="Деталі" />
      <div className="Page_main_block">
        <Img
          fluid={data.img.childImageSharp.fluid}
          className="Page_main_block_img-right"
        />
      </div>
      <div className="Page_main_block">
        <p>
          Всього <strong>три простих кроки:</strong>
        </p>
        <p>1) ДІВСервіс досліджує місце розташування</p>
        <p>2) ДІВСервіс розміщує монітор, та під'єднує до системи</p>
        <p>
          3) ДІВСервіс створює та публікує оголошення,{" "}
          <strong>згідно з Вашими запитами.</strong>
        </p>
        <h6>Ваше побажання - наше виконання!</h6>
      </div>
    </Page>
  );
};

export default DetailsPage;
